/* Body and Core Css */
* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;

  &:after,
  &:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font: {
    size: 14px;
    weight: 600;
    family: 'Nunito', sans-serif;
  }
  line-height: 1.3;
  text-align: center;
  color: $color-grey;
  background: $background-color;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  max-width: 1350px;
  margin: auto;
  padding: 50px 2rem;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: $element-color;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: $color-main;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-main;
}

/* Typography */
h1 {
  font-size: 2.5rem;
  margin-bottom: 0;
}

h2 {
  font-size: 2rem;
  margin-bottom: 0;
}

h3 {
  font-size: 1.75rem;
  margin-bottom: 0;
}

h4 {
  font-size: 1.2rem;
  margin-bottom: 0;
}

h5 {
  font-size: 1.25rem;
  margin-bottom: 0;
}

h6 {
  font-size: 1rem;
  margin-bottom: 0;
}

a {
  color: $color-white;
  outline: none;
  text-decoration: none;

  &:hover {
    color: $color-main;
    outline: none;
    text-decoration: none;
  }
}

p {
  font: {
    size: 15px;
    weight: 600;
  }
  line-height: 1.7;
}

img {
  max-width: 100%;
}

dl,
ol,
ul {
  padding: 0;
}

/* Auth */
.auth {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .ant-row {
    width: 100%;
  }

  .logo {
    margin-bottom: 40px;

    img {
      max-width: 150px;
    }

    h1 {
      text-transform: uppercase;
      background: $text-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .ant-form-item {
    .ant-form-item-row {
      gap: 7px;
    }
  }
}

/* User */
.textInput {
  display: flex;
  align-items: center;
  gap: 15px;

  &__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
  }

  &__input {
    all: unset;
    width: 40px;
    padding: 0 5px;
    border: 1px solid transparent;
    border-radius: 3px;
    appearance: none;
    -moz-appearance: textfield;
    transition: background-color 0.5s ease-in-out;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    text-align: left;
    font-weight: 700;
    font-size: 20px;
    color: #00c39a;

    &:focus {
      border: 1px solid #00c39a;
    }

    &.request-success {
      background-color: rgba(#00c39a, 0.5);
    }
  }
}

.user {
  position: relative;

  .ant-col {
    padding: 0.5rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actionPanel {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    .infoPanel {
      &__infocol {
        display: flex;
        align-items: center;
        gap: 15px;
      }

      &__label {
        font-size: 14px;
        font-weight: 400;
        line-height: 100%;
      }

      &__value {
        font-weight: 700;
        font-size: 20px;
        color: #00c39a;
      }
    }

    .button {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 7px 10px;
      gap: 7px;
      font-size: 14px;
      border: 1px solid $background-grey-200;
      border-radius: 8px;
      transition: all 0.3s;

      &:hover {
        background: $background-grey-200;
      }
    }
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 20px;
    color: $color-grey;
  }

  .stat-info {
    display: flex;
    font-size: 14px;
    padding: 5px;
    gap: 40px;
    margin-bottom: 20px;

    span {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 7px;

      &:first-child {
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          top: 0;
          right: -20px;
          background-color: #383b40;
        }
      }

      s {
        margin-right: 5px;
        padding: 5px;
        border-radius: 5px;
        color: #d94c48;
        background: rgba(217, 76, 72, 0.15);
      }
    }

    b {
      font-weight: 700;
      font-size: 20px;
      color: $color-main;
    }
  }

  .box {
    position: relative;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    background-color: rgba(34, 35, 37, 0.5);

    .result {
      position: relative;
      height: 100%;
      padding: 20px;
      border-radius: 8px;
      // margin-bottom: 30px;
      background-color: $element-color;

      h4 {
        text-align: left;
        margin-bottom: 30px;
      }

      .ant-form-item {
        width: 100%;
        margin-bottom: 0;
      }

      .amount {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
      }

      .chart {
        .apexcharts-gridline {
          stroke: #2c2c2c;
        }

        .apexcharts-grid-borders {
          stroke: #2c2c2c;

          line {
            &:last-child {
              stroke: #464646;
            }
          }
        }

        .apexcharts-xaxis-tick {
          stroke: #464646;
        }

        .apexcharts-text {
          fill: #464646;
          font-family: 'Nunito', sans-serif !important;

          &.apexcharts-yaxis-title-text {
            fill: $color-grey;
          }
        }

        .apexcharts-legend-text {
          color: $color-grey !important;
          font-family: 'Nunito', sans-serif !important;
        }

        .apexcharts-marker {
          stroke: $background-color;
        }

        .apexcharts-legend-marker {
          border-width: 2px !important;
          border-color: #1b1c1d !important;
        }

        .apexcharts-xaxistooltip,
        .apexcharts-yaxistooltip {
          color: $color-grey;
          border: none;
          background: $background-grey-200;
          box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.4);

          .apexcharts-xaxistooltip-text {
            font-family: 'Nunito', sans-serif !important;
          }
        }

        .apexcharts-xaxistooltip-bottom {
          &::after,
          &::before {
            border-bottom-color: $background-grey-200;
          }
        }

        .apexcharts-tooltip {
          &.apexcharts-theme-light {
            color: $color-grey;
            border: none;
            background: $background-grey-200;
            box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.4);

            .apexcharts-tooltip-title {
              border: none;
              background: #2c2f32;
              font-family: 'Nunito', sans-serif !important;
            }

            .apexcharts-tooltip-text {
              font-family: 'Nunito', sans-serif !important;
            }
          }
        }
      }

      .no-week-message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        color: #666768;
      }

      &.inactive {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .closed-clients {
      margin-top: 30px;

      h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
      }
    }
  }
}

/* Admin */
.admin {
  position: relative;
  display: flex;
  max-width: 100%;
  min-height: 100vh;
  padding: 20px;

  .side-bar {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 20px 0 0;

    .logo {
      margin-bottom: 50px;
      width: 50px;
    }

    .side-bar-menu {
      a,
      .link {
        padding-left: 8px;
      }
    }

    .subtitle {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      gap: 7px;
      padding: 0 7px;
      opacity: 0.5;
      margin-bottom: 10px;
    }
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 20px;
    color: $color-grey;

    span {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 14px;
      padding: 5px;

      b {
        font-size: 20px;
        color: $color-main;
      }
    }
  }

  .box {
    position: relative;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    background-color: rgba(34, 35, 37, 0.5);

    h4 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .actionBar {
      margin-top: 20px;
    }

    .office {
      margin-top: 0;
      color: $color-grey;
      font-family: 'Nunito', sans-serif;
    }

    .moderator {
      position: relative;

      h5 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 20px;

        .ant-form-item-control-input {
          min-width: 400px;
        }
      }

      .history {
        position: relative;
        padding: 20px;
        height: 460px;
        overflow: hidden auto;
        border-left: 1px solid $element-color;

        .item {
          border: none;

          .item__content {
            display: grid;
            grid-template-columns: 0.4fr 0.5fr 1fr 2fr;

            .name {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 15px;
              color: $color-grey;

              .name-subtitle {
                opacity: 0.5;
              }
            }

            .name-title {
              color: $color-white;
            }
          }

          .item__desc {
            display: flex;
            flex-direction: column;

            .item__desc-name {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 5px 0;

              span {
                display: flex;
                align-items: center;
                gap: 7px;

                s {
                  color: $color-main;
                }
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .empty-message {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          .message {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
          }
        }
      }
    }

    .result {
      position: relative;

      .titleWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      h5 {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        font-size: 16px;
      }

      .stat {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-radius: 8px;
        outline: 1px solid $background-color !important;
        outline-offset: 3px;
        background: $background-color;
        margin-bottom: 20px;

        .item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          b {
            font-size: 16px;
            font-weight: 600;
            color: $color-main;
          }
        }
      }

      .filter {
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        .rangePickerContainer {
          width: 100%;
          max-width: 300px;
        }

        .rangePicker {
          width: 100%;
          max-width: 500px;
          margin-right: auto;
        }
      }

      .ant-form-item {
        width: 100%;
        margin-bottom: 0;
      }

      .result-box {
        position: relative;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 30px;
        background-color: $element-color;

        h4 {
          text-align: left;
          margin-bottom: 30px;
        }
      }

      .chart {
        .apexcharts-gridline {
          stroke: #2c2c2c;
        }

        .apexcharts-grid-borders {
          stroke: #2c2c2c;

          line {
            &:last-child {
              stroke: #464646;
            }
          }
        }

        .apexcharts-xaxis-tick {
          stroke: #464646;
        }

        .apexcharts-text {
          fill: #464646;
          font-family: 'Nunito', sans-serif !important;

          &.apexcharts-yaxis-title-text {
            fill: $color-grey;
          }
        }

        .apexcharts-legend-text {
          color: $color-grey !important;
          font-family: 'Nunito', sans-serif !important;
        }

        .apexcharts-marker {
          stroke: $background-color;
        }

        .apexcharts-legend-marker {
          border-width: 2px !important;
          border-color: #1b1c1d !important;
        }

        .apexcharts-xaxistooltip,
        .apexcharts-yaxistooltip {
          color: $color-grey;
          border: none;
          background: $background-grey-200;
          box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.4);

          .apexcharts-xaxistooltip-text {
            font-family: 'Nunito', sans-serif !important;
          }
        }

        .apexcharts-xaxistooltip-bottom {
          &::after,
          &::before {
            border-bottom-color: $background-grey-200;
          }
        }

        .apexcharts-tooltip {
          &.apexcharts-theme-light {
            color: $color-grey;
            border: none;
            background: $background-grey-200;
            box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.4);

            .apexcharts-tooltip-title {
              border: none;
              background: #2c2f32;
              font-family: 'Nunito', sans-serif !important;
            }

            .apexcharts-tooltip-text {
              font-family: 'Nunito', sans-serif !important;
            }
          }
        }
      }

      .no-week-message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        color: #666768;
      }

      &.inactive {
        opacity: 0.5;
        pointer-events: none;
      }

      .chartsRow {
        padding-top: 40px;
      }
    }

    .estimated {
      h5 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        font-size: 16px;
      }

      .stat {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 15px;
        border-radius: 8px;
        outline: 1px solid $background-color !important;
        outline-offset: 3px;
        background: $background-color;
        margin-bottom: 20px;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          b {
            font-size: 16px;
            font-weight: 600;
            color: $color-main;
          }
        }
      }
    }

    .staff {
      .title {
        .total {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 14px;
          padding: 5px;
          text-align: right;

          b {
            font-size: 20px;
            color: $color-main;
          }

          &:nth-last-child(2) {
            margin-left: auto;
          }
        }
      }
    }

    .actionBar {
      display: flex;
      justify-content: center;
    }
  }

  .toggle-sidebar-btn {
    position: absolute;
    display: none;
    top: 46px;
    left: 40px;
    padding: 5px;
    border-color: $element-color;
    z-index: 1;

    &.active {
      left: 250px;
    }
  }
}

/* Pagination */

.pagination {
  margin-top: 16px;

  &__list {
    display: flex;
    justify-content: flex-end;
  }

  &__item {
    display: inline-block;
    font-family: 'Nunito', sans-serif;
    background-color: #222325;
    min-width: 32px;
    height: 32px;
    margin-inline-end: 8px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &__active {
      border: none;
      background-color: rgba(0, 195, 154, 0.1);
      outline: 1px solid rgba(0, 195, 154, 0.3) !important;
      outline-offset: 2px;

      .pagination__button {
        color: #00c39a;
        font-weight: 600;
      }
    }

    &:hover {
      background-color: #222325 !important;
      outline: 1px solid #222325 !important;
      outline-offset: 2px;

      .pagination__button {
        color: #00c39a;
        font-weight: 600;
        opacity: 0.5;
      }
    }
  }

  &__button {
    all: unset;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 0 6px;
    transition: all 0.3s;
    color: #ababab;
  }

  &__navigationButton {
    font-size: 12px;
    line-height: 0;
  }
}

.ant-checkbox {
  border-spacing: 0;
}

.exportButton {
  padding: 0;
  border: 0;
  box-shadow: none;
  outline: none;

  &:hover {
    background-color: unset;
  }
}

/* Datarangepicker */
@media (max-width: 767px) {
  .datarangePopup .ant-picker-panels .ant-picker-panel:last-of-type {
    display: none;
  }
}
