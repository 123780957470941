/* xxl, large: 1350px */
$breakpoint-xxl: 1350px;
/* xl, large: 1200px */
$breakpoint-xl: 1200px;
/* lg, large: 992px */
$breakpoint-lg: 992px;
/* md, large: 768px */
$breakpoint-md: 768px;
/* sm, large: 575px */
$breakpoint-sm: 575px;

@media screen and (max-width: $breakpoint-xxl) {
  section {
    max-width: 1200px;
  }
}

@media screen and (max-width: $breakpoint-xl) {
}

@media screen and (max-width: $breakpoint-lg) {
  .user {
    .box {
      .office {
        h4 {
          flex-direction: column;

          .total {
            align-items: center;
          }
        }

        .filter {
          margin-top: 30px;
          width: 100%;

          .ant-form-item {
            width: 100%;
          }
        }
      }
    }
  }

  .admin {
    .side-bar {
      position: fixed;
      top: 0;
      left: -250px;
      height: 100vh;
      padding: 15px;
      background: #151515;
      border-right: 1px solid #202321;
      transition: all 0.3s;
      z-index: 9;

      &.active {
        left: 0;
      }
    }

    .box {
      .title {
        padding-left: 55px;
      }

      .moderator {
        .history {
          padding: 0 10px 0 0;
          border-left: none;

          .item {
            gap: 15px;
            grid-template-columns: 1fr;

            .name {
              gap: 5px;
            }
          }
        }
      }
    }

    .toggle-sidebar-btn {
      display: flex;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
}

@media screen and (max-width: $breakpoint-sm) {
  section {
    padding: 25px 1rem;
  }

  .user {
    h2 {
      font-size: 22px;

      span {
        font-size: 13px;
        padding: 5px 7px;
      }
    }

    .stat-info {
      justify-content: center;

      span {
        flex-direction: column;
        align-items: stretch;
      }
    }

    .box {
      padding: 10px 0;

      .result {
        .amount {
          flex-direction: column;
          gap: 15px;
        }
      }

      .office {
        h4 {
          .total {
            s {
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  .admin {
    .box {
      .title {
        padding-left: 0;
        flex-direction: column;
        margin-top: 10px;
        gap: 20px;
      }

      .result {
        .stat {
          align-items: flex-start;
          flex-direction: column;
          gap: 20px;
        }

        .filter {
          flex-direction: column;
          gap: 10px;
        }
      }

      .staff {
        .title {
          .total {
            align-items: center;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .user {
    .box {
      .closed-clients {
        h4 {
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  }
}
