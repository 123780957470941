$color-white: #fff;
$color-black: #000;
$color-grey: #ababab;
$color-main: #00c39a;
$color-red: #d94c48;
$element-color: #222325;
$background-color: #151515;
$background-grey-200: #383b40;
$error-border: rgba(217, 76, 72, 0.5);
$background-main-100: rgba(0, 195, 154, 0.1);
$color-outline-input: rgba(0, 195, 154, 0.6);
$background-main: rgba(0, 195, 154, 0.1);
$text-gradient: linear-gradient(179.14deg, #ffffff 7.77%, #444444 120.01%);
